<template>
  <div class="card">
    <div class="card-body">
      <template>
        <h5 align="center">{{ user.name }} ({{ user.role.name }})</h5>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <table class="table  table-responsive-sm">
              <tbody>
                <tr>
                  <td>
                    <strong>Email</strong>
                  </td>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Phone Number</strong>
                  </td>
                  <td>{{ user.phone_number }}</td>
                </tr>
                <tr>
                  <td>
                    <strong>ID number</strong>
                  </td>
                  <td>{{ user.id_number }}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Joined On</strong>
                  </td>
                  <td>{{ user.created_at }}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Status</strong>
                  </td>
                  <td>
                    <span
                      :class="
                        `badge badge-${user.active ? 'success' : 'danger'}`
                      "
                      >{{ user.active ? "Active" : "Suspended" }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Action</strong>
                  </td>
                  <td>
                    <a
                      href="#"
                      class="btn btn-danger btn-sm"
                      @click.prevent="suspend"
                      v-if="user.active == 1"
                      >Suspend</a
                    >
                    <a
                      href="#"
                      class="btn btn-success btn-sm"
                      @click.prevent="activate"
                      v-if="user.active == 0"
                      >Activate</a
                    >
                    <router-link
                      :to="{
                        name: 'users.edit',
                        params: { id: this.$route.params.id },
                      }"
                      class="btn btn-warning btn-sm ml-2"
                      >Edit</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5>User Permissions</h5>
                <hr />
                <ol>
                  <li
                    v-for="(permission, i) in user.role.permissions"
                    :key="`permission-${i}`"
                  >
                    {{ permission }}
                  </li>
                </ol>
                <br />
                <a
                  href="#"
                  class="btn btn-secondary btn-sm"
                  @click.prevent="show_roles_modal = true"
                  >Change Role</a
                >
              </div>
            </div>
          </div>
        </div>

        <modal :showModal="show_roles_modal" @close="show_roles_modal = false">
          <template v-slot:header>
            <h5>Change Role</h5>
          </template>
          <form @submit.prevent="changeRole">
            <div class="form-group">
              <label for="">Select Role</label>
              <select class="form-control" v-model="new_role">
                <option
                  v-for="(role, i) in roles"
                  :value="role.name"
                  :key="`role-${i}`"
                  >{{ role.name }}</option
                >
              </select>
            </div>
            <div class="form-group">
              <button class="btn btn-primary">Save</button>
            </div>
          </form>
        </modal>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
    this.fetchRoles();
  },

  data() {
    return {
      user: { role: {}},
      show_roles_modal: false,
      roles: [],
      new_role: "",
      error: "",
    };
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios
        .get("/api/v1/dashboard/users/" + this.$route.params.id)
        .then((response) => {
          this.user = response.data;
          this.$loader.stop()
        });
    },

    fetchRoles() {
      this.$axios.get("/api/v1/dashboard/users/create").then((response) => {
        this.roles = response.data;
      });
    },

    changeRole() {
      let data = {
        id: this.user.id,
        role_id: this.new_role,
      };

      this.$axios
        .put(`/api/v1/dashboard/users/${this.$route.params.id}`, data)
        .then(() => {
          // this.show_roles_modal = false;
          // this.fetch();
        })
    },

    suspend() {
      this.$axios
      .post(`/api/v1/dashboard/suspend-user?userid=${this.$route.params.id}`)
      .then((response) => {
        if(response.data.error) {
          this.$toast.error = response.data.error
          return
        }else if(response.data.message == 'User Activated Successfully'){
          this.$toast.success('User Activated Successfully');

        }
        });
    },

    activate() {
      this.$axios
        .post(`/api/v1/dashboard/activate-user?userid=${this.$route.params.id}`)
        .then((response) => {

          if(response.data.error) {
            this.$toast.error = response.data.error
            return
          }else if(response.data.message == 'User Activated Successfully'){
            this.$toast.success('User Activated Successfully');

          }
          this.fetch();
        });
    },
  },
};
</script>
